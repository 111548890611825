import React from 'react';

import './styled-header-img.css';

const StyledHeaderImg = () => {
    return ( 
        <header className="styledHeader"></header>
     );
}
 
export default StyledHeaderImg;